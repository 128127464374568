.searchBox {
    height: 100px;
}

.todolists {
    height: 100px;
}

.inputTodo {
    height: 100px;
}